export default {
  items: [
    {
      name: "Restaurants",
      url: "/restaurants",
      icon: "cil-house",
    },
    {
      name: "Orders",
      url: "/orders",
      icon: "cil-house",
    },
    {
      name: "Customers",
      url: "/customers",
      icon: "cil-user",
    },
    {
      name: "Drivers",
      url: "/drivers",
      icon: "cil-user",
    },
    {
      name: "Restaurant Types",
      url: "/restaurant-types",
      icon: "cil-user",
    },
    {
      name: "Categories",
      url: "/categories",
      icon: "cil-user",
    },
  ],
};

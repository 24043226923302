export default function getLoginRequest(
  state = { error: null, success: null, processing: "" },
  action
) {
  switch (action.type) {
    case "FETCH_LOGIN_SUCCESS":
      return { ...state, ...{ success: action.success, error: null } };
    case "FETCH_LOGIN_ERROR":
      return { ...state, ...{ success: null, error: action.error.response } };
    case "FETCH_LOGIN_PROCESSING":
      return { ...state, ...{ processing: action.processing } };
    case "RESET_FETCH_LOGIN":
      return { ...state, ...{ success: null, error: null } };
    default:
      return state;
  }
}

export const parseTime = (data) => {
  if (!data) {
    return "-";
  }
  let hour = Math.floor(data / 60);
  if (hour > 24) {
    return `${Math.floor(hour / 24)}d ago`;
  }
  if (hour < 1) {
    return `0:${data}h ago`;
  }
  let minutes = data / 60;
  minutes = Math.ceil(minutes / 60);
  if (hour / 10 < 1) {
    hour = `0${hour}`;
  }
  if (minutes / 10 < 1) {
    minutes = `0${minutes}`;
  }
  return `${hour}:${minutes}h ago`;
};

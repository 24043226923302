export default function getRestaurantCategoryPriorities(
  state = { error: null, success: null, processing: '' },
  action
) {
  switch (action.type) {
    case 'FETCH_RESTAURANT_CATEGORIES_PRODUCTS_SUCCESS':
      return { ...state, ...{ success: action.success } };
    case 'FETCH_RESTAURANT_CATEGORIES_PRODUCTS_ERROR':
      return { ...state, ...{ error: action.error } };
    case 'FETCH_RESTAURANTS_PROCESSING':
      return { ...state, ...{ processing: action.processing } };
    default:
      return state;
  }
}

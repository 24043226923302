import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { storage } from "./utils";
import Login from "./views/Pages/Login";
import DefaultLayout from "./containers/DefaultLayout";

class Routes extends Component {
  state = {
    isLoggedIn: false,
  };
  componentDidMount() {
    if (storage.getToken()) {
      this.setState({
        isLoggedIn: true,
      });
    }
  }
  render() {
    const { isLoggedIn } = this.state;
    if (isLoggedIn) {
      return (
        <Switch>
          <Route path="/" component={DefaultLayout}></Route>
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/" component={Login}></Route>
        </Switch>
      );
    }
  }
}

export default Routes;

export default function getAvailableCreditPoints(
  state = { error: null, success: null },
  action
) {
  switch (action.type) {
    case 'FETCH_AVAILABLE_CREDIT_POINTS_SUCCESS':
      return { ...state, ...{ success: action.success } };
    case 'FETCH_AVAILABLE_CREDIT_POINTS_ERROR':
      return { ...state, ...{ error: action.error } };
    default:
      return state;
  }
}

import React from 'react';

const Products = React.lazy(() =>
  import('./views/Restaurants/Products/Products')
);
const Restaurants = React.lazy(() => import('./views/Restaurants'));
const AddEditRestaurant = React.lazy(() =>
  import('./views/Restaurants/AddEditRestaurant')
);
const ViewRestaurant = React.lazy(() =>
  import('./views/Restaurants/RestaurantInfo')
);
const Addons = React.lazy(() =>
  import('./views/Restaurants/Products/Addons/Addons')
);
const Sizes = React.lazy(() =>
  import('./views/Restaurants/Products/Sizes/Sizes')
);
const Dashboard = React.lazy(() => import('./views/Pages/Dashboard'));

const Orders = React.lazy(() => import('./views/Orders/Orders'));

const OrderDetail = React.lazy(() => import('./views/Orders/OrderDetails'));
const OrderReviews = React.lazy(() => import('./views/Orders/Review'));

const RestaurantUsers = React.lazy(() =>
  import('./views/Restaurants/Users/Users')
);

const Customers = React.lazy(() => import('./views/Customers'));

const CustomerInfo = React.lazy(() => import('./views/Customers/CustomerInfo'));

const Drivers = React.lazy(() => import('./views/Drivers'));

const RestaurantTypes = React.lazy(() => import('./views/RestaurantTypes'));

const Categories = React.lazy(() => import('./views/Categories'));

const DeliveryCharges = React.lazy(() =>
  import('./views/Restaurants/DeliveryCharges')
);

const DriverInfo = React.lazy(() => import('./views/Drivers/DriverInfo'));

const ProductProperties = React.lazy(() =>
  import('./views/Restaurants/Products/ProductProperties')
);

const ProductPropertyDetail = React.lazy(() =>
  import('./views/Restaurants/Products/ProductProperties/ProductPropertyDetail')
);

const RestaurantCategoryPriorities = React.lazy(() =>
  import('./views/Restaurants/CategoryPriorities')
);

const CreditPoints = React.lazy(() =>
  import('./views/Customers/CreditPoints')
);

const routes = [
  {
    path: '/dashboard',
    exact: true,
    name: 'Home',
    component: Dashboard,
  },
  {
    path: '/restaurants',
    exact: true,
    name: 'Restaurants',
    component: Restaurants,
  },
  {
    path: '/restaurants/new',
    exact: true,
    name: 'New',
    component: AddEditRestaurant,
  },
  {
    path: '/restaurants/:id',
    exact: true,
    name: 'View',
    component: ViewRestaurant,
  },
  {
    path: '/restaurants/:id/edit',
    exact: true,
    name: 'Edit',
    component: AddEditRestaurant,
  },
  {
    path: '/restaurants/:id/products',
    exact: true,
    name: 'Category Priorities',
    component: Products,
  },
  {
    path: '/restaurants/:id/category-priorities',
    exact: true,
    name: 'Products',
    component: RestaurantCategoryPriorities,
  },
  {
    path: '/restaurants/:id/products/:prodid/addons',
    exact: true,
    name: 'Addons',
    component: Addons,
  },
  {
    path: '/restaurants/:id/products/:prodid/properties',
    exact: true,
    name: 'Properties',
    component: ProductProperties,
  },
  {
    path: '/restaurants/:id/products/:prodid/properties/:propertyid/details',
    exact: true,
    name: 'Details',
    component: ProductPropertyDetail,
  },
  {
    path: '/restaurants/:id/products/:prodid/sizes',
    exact: true,
    name: 'Sizes',
    component: Sizes,
  },
  {
    path: '/orders',
    exact: true,
    name: 'Orders',
    component: Orders,
  },
  {
    path: '/orders/:id',
    exact: true,
    name: 'View',
    component: OrderDetail,
  },
  {
    path: '/orders/:id/reviews',
    exact: true,
    name: 'Review',
    component: OrderReviews,
  },
  {
    path: '/restaurants/:id/users',
    exact: true,
    name: 'Users',
    component: RestaurantUsers,
  },
  {
    path: '/customers',
    exact: true,
    name: 'Customers',
    component: Customers,
  },
  {
    path: '/customers/:id',
    exact: true,
    name: 'View',
    component: CustomerInfo,
  },
  {
    path: '/customers/:id/credit-points',
    exact: true,
    name: 'Credit Points',
    component: CreditPoints,
  },
  {
    path: '/drivers',
    exact: true,
    name: 'Drivers',
    component: Drivers,
  },
  {
    path: '/drivers/:id',
    exact: true,
    name: 'View',
    component: DriverInfo,
  },
  {
    path: '/restaurant-types',
    exact: true,
    name: 'Restaurant Types',
    component: RestaurantTypes,
  },
  {
    path: '/categories',
    exact: true,
    name: 'Categories',
    component: Categories,
  },
  {
    path: '/restaurants/:id/delivery-charges',
    exact: true,
    name: 'Delivery Charges',
    component: DeliveryCharges,
  },
];

export default routes;

export default function assignDriverToOrderUpdate(
    state = { validation: null, success: null, badGateway: null },
    action
  ) {
    switch (action.type) {
      case "ASSIGN_ORDER_UPDATE_SUCCESS":
        return { ...state, ...{ success: action.success } };
      case "ASSIGN_ORDER_UPDATE_VALIDATION_ERROR":
        return { ...state, ...{ validation: action.validation } };
      case "ASSIGN_ORDER_UPDATE_BAD_GATEWAY_ERROR":
        return { ...state, ...{ badGateway: action.gateway } };
      default:
        return state;
    }
  }
  
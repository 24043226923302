export default function successMessage(
  state = {
    message: {
      type: "",
      message: "",
      title: "",
      persistent: false,
    },
    outerMessage: {
      type: "",
      message: "",
      title: "",
      persistent: false,
    },
  },
  action
) {
  switch (action.type) {
    case "ADD_SUCCESS_MESSAGE":
      return { ...state, ...{ message: action.message } };
    case "DELETE_SUCCESS_MESSAGE":
      return { ...state, ...{ message: "" } };
    case "ADD_MESSAGE":
      return { ...state, ...{ message: action.message } };
    case "DELETE_MESSAGE":
      return { ...state, ...{ message: action.message } };
    case "ADD_OUTER_MESSAGE":
      return { ...state, ...{ outerMessage: action.message } };
    case "DELETE_OUTER_MESSAGE":
      return { ...state, ...{ outerMessage: action.message } };
    default:
      return state;
  }
}

import { storageConstants } from "../constants";

const getToken = () => {
  return localStorage.getItem(storageConstants.TOKEN);
};

const setToken = (key, value) => {
  return localStorage.setItem(key, value);
};

const logout = () => {
  return localStorage.clear();
};

export const storage = {
  getToken,
  setToken,
  logout,
};

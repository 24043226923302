export default function editProduct(
  state = { validation: null, success: null },
  action
) {
  switch (action.type) {
    case "UPDATE_PRODUCT_SUCCESS":
      return { ...state, ...{ success: action.success } };
    case "UPDATE_PRODUCT_VALIDATION_ERROR":
      return { ...state, ...{ validation: action.validation } };
    default:
      return state;
  }
}

export default function updatePassword(
  state = { validation: null, success: null },
  action
) {
  switch (action.type) {
    case 'UPDATE_PASSWORD_SUCCESS':
      return { ...state, ...{ success: action.success } };
    case 'UPDATE_PASSWORD_VALIDATION_ERROR':
      return { ...state, ...{ validation: action.validation } };
    default:
      return state;
  }
}

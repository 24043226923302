import { combineReducers } from 'redux';

import getLoginRequest from './auth/login_request';
import getProducts from './products/get_products';
import createProduct from './products/create_product';
import deleteProduct from './products/delete_product';
import editProduct from './products/edit_product';
import importProduct from './products/import_product';
import importProductProperty from './products/import_product_property';
import getRestaurants from './restaurants/get_restaurants';
import getRestaurant from './restaurants/get_restaurant';
import getRestaurantTypes from './restaurants/get_restaurant_types';
import createRestaurant from './restaurants/create_restaurant';
import updateRestaurant from './restaurants/update_restaurant';
import errorHandling from './error';
import successMessage from './success_message';
import getCurrencies from './currencies/get_currencies';
import getTaxes from './get_taxes';
import getCategories from './categories/get_categories';
import createCategory from './categories/create_category';
import updateCategory from './categories/update_category';
import deleteCategory from './categories/delete_category';
import getAddons from './addons/get_addons';
import createAddon from './addons/create_addon';
import deleteAddon from './addons/delete_addon';
import editAddon from './addons/edit_addon';
import getSizes from './sizes/get_sizes';
import createSize from './sizes/create_size';
import deleteSize from './sizes/delete_size';
import editSize from './sizes/edit_size';
import getOrders from './orders/get_orders';
import getOrder from './orders/get_order';
import getOrderReviews from './orders/get_order_reviews';
import updateOrderStatus from './orders/update_order_status';
import getDays from './get_days';
import storeRestaurantRoutine from './restaurants/store_restaurant_routine';
import getRestaurantRoutine from './restaurants/get_restaurant_routine';
import getRestaurantUsers from './restaurants/get_restaurant_users';
import updatePassword from './restaurants/update_password';
import createRestaurantUser from './restaurants/create_restaurant_user';
import getRestaurantCategoryPriorities from './restaurants/get_restaurant_category_priorities';
import updateRestaurantCategoryPriorities from './restaurants/update_restaurant_category_priorities';
import getCustomers from './customers/get_customers';
import getCustomer from './customers/get_customer';
import getDrivers from './drivers/get_drivers';
import createDriver from './drivers/create_driver';
import updateDriver from './drivers/update_driver';
import deleteDriver from './drivers/delete_driver';
import getDriver from './drivers/get_driver';
import createRestaurantType from './restaurantTypes/create_restaurant_types';
import deleteRestaurantType from './restaurantTypes/delete_restaurant_type';
import getGlobalRestaurantTypes from './restaurantTypes/get_restaurant_types';
import updateRestaurantType from './restaurantTypes/update_restaurant_type';
import assignDriverToOrder from './orders/assign_driver_to_order';
import assignDriverToOrderUpdate from './orders/assign_driver_to_order_update';
import createDeliveryCharge from './delieryCharges/create_delivery_charge';
import deleteDeliveryCharge from './delieryCharges/delete_delivery_charge';
import getDeliveryCharges from './delieryCharges/get_delivery_charges';
import updateDeliveryCharge from './delieryCharges/update_delivery_charge';
import getAllProductProperties from './productProperties/get_all_product_properties';
import createProductProperty from './productProperties/create_product_property';
import deleteProductProperty from './productProperties/delete_product_property';
import updateProductProperty from './productProperties/update_product_property';
import getAllProductPropertyDetails from './productPropertyDetails/get_all_propduct_property_details';
import createProductPropertyDetail from './productPropertyDetails/create_product_property_detail';
import deleteProductPropertyDetail from './productPropertyDetails/delete_product_property_detail';
import updateProductPropertyDetail from './productPropertyDetails/update_product_property_detail';
import addCreditPoint from './credit-points/addCreditPoint';
import getAvailableCreditPoints from './credit-points/getAvailableCreditPoints';
import getCreditPointLogs from './credit-points/getCreditPointLogs';

const allReducers = combineReducers({
  getLoginRequest,
  getProducts,
  createProduct,
  editProduct,
  deleteProduct,
  importProduct,
  importProductProperty,
  getRestaurants,
  getRestaurant,
  getRestaurantTypes,
  createRestaurant,
  updateRestaurant,
  errorHandling,
  successMessage,
  getCurrencies,
  getTaxes,
  getCategories,
  createCategory,
  updateCategory,
  deleteCategory,
  getAddons,
  createAddon,
  deleteAddon,
  editAddon,
  getSizes,
  createSize,
  deleteSize,
  editSize,
  getOrders,
  getOrder,
  getOrderReviews,
  updateOrderStatus,
  getDays,
  storeRestaurantRoutine,
  getRestaurantRoutine,
  getRestaurantUsers,
  updatePassword,
  createRestaurantUser,
  getCustomers,
  getCustomer,
  getDrivers,
  createDriver,
  updateDriver,
  deleteDriver,
  getDriver,
  createRestaurantType,
  deleteRestaurantType,
  getGlobalRestaurantTypes,
  updateRestaurantType,
  assignDriverToOrder,
  assignDriverToOrderUpdate,
  createDeliveryCharge,
  deleteDeliveryCharge,
  getDeliveryCharges,
  updateDeliveryCharge,
  getAllProductProperties,
  createProductProperty,
  updateProductProperty,
  deleteProductProperty,
  getAllProductPropertyDetails,
  createProductPropertyDetail,
  deleteProductPropertyDetail,
  updateProductPropertyDetail,
  getRestaurantCategoryPriorities,
  updateRestaurantCategoryPriorities,
  getCreditPointLogs,
  addCreditPoint,
  getAvailableCreditPoints
});

export default allReducers;

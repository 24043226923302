export default function getSizes(
  state = { error: null, success: null },
  action
) {
  switch (action.type) {
    case "FETCH_SIZES_SUCCESS":
      return { ...state, ...{ success: action.success } };
    case "FETCH_SIZES_ERROR":
      return { ...state, ...{ error: action.error } };
    default:
      return state;
  }
}

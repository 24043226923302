export default function getCreditPointLogs(
  state = { error: null, success: null },
  action
) {
  switch (action.type) {
    case 'FETCH_CREDIT_POINT_LOGS_SUCCESS':
      return { ...state, ...{ success: action.success } };
    case 'FETCH_CREDIT_POINT_LOGS_ERROR':
      return { ...state, ...{ error: action.error } };
    default:
      return state;
  }
}

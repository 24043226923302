export default function getOrder(
    state = { error: null, success: null, processing: "" },
    action
  ) {
    switch (action.type) {
      case "FETCH_ORDER_SUCCESS":
        return { ...state, ...{ success: action.success } };
      case "FETCH_ORDER_ERROR":
        return { ...state, ...{ error: action.error } };
      default:
        return state;
    }
  }
  
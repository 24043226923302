import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";
import store from "../../../Store";
import { getLoginRequest } from "../../../actions/auth/login_request";
import { storageConstants } from "../../../constants";
import { storage } from "../../../utils";

class Login extends Component {
  state = {
    data: {
      username: "",
      password: "",
    },
    error: {
      username: "",
      password: "",
    },
    restError: "",
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.getLoginRequest !== prevProps.getLoginRequest) {
      let { success, error } = this.props.getLoginRequest;
      if (error) {
        if (error.status === 422) {
          this.setState({ error: error.data.message });
        } else {
          toast.error(error.data.message);
        }
      } else if (success) {
        storage.setToken(storageConstants.TOKEN, success.data.access_token);
        storage.setToken(storageConstants.REFRESH, success.data.refresh_token);
        window.location.href = `/restaurants`;
      }
    }
  }

  onChange = (e) => {
    const { name, value } = e.target;
    let { data, error } = this.state;
    data[name] = value;
    error[name] = "";
    this.setState({ data, error });
  };

  onSubmit = () => {
    let { data } = this.state;
    store.dispatch(getLoginRequest(data));
  };

  render() {
    const { data, error } = this.state;
    return (
      <div className="app flex-row align-items-center">
        <ToastContainer />
        <Container>
          <Row className="justify-content-center">
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Login</h1>
                      <p className="text-muted">Sign In to your account</p>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={`${error.username ? "login-error" : ""}`}
                          >
                            <i
                              className={`icon-user ${
                                error.username ? "login-error-text" : ""
                              }`}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="username"
                          type="text"
                          placeholder="Username"
                          autoComplete="username"
                          value={data.username}
                          onChange={this.onChange}
                          className={`form-control ${
                            error.username ? "is-invalid" : ""
                          }`}
                        />
                        {error.username && (
                          <FormFeedback className="help-block">
                            {error.username}
                          </FormFeedback>
                        )}
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            className={`${error.password ? "login-error" : ""}`}
                          >
                            <i
                              className={`icon-lock ${
                                error.password ? "login-error-text" : ""
                              }`}
                            ></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="password"
                          type="password"
                          placeholder="Password"
                          autoComplete="current-password"
                          value={data.password}
                          onChange={this.onChange}
                          className={`form-control ${
                            error.password ? "is-invalid" : ""
                          }`}
                        />
                        {error.password && (
                          <FormFeedback className="help-block">
                            {error.password}
                          </FormFeedback>
                        )}
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          <Button
                            color="primary"
                            className="px-4"
                            onClick={this.onSubmit}
                          >
                            Login
                          </Button>
                        </Col>
                        <Col xs="6" className="text-right">
                          <Button color="link" className="px-0">
                            Forgot password?
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { getLoginRequest, successMessage } = state;
  return {
    getLoginRequest,
    successMessage,
  };
}

export default connect(mapStateToProps)(Login);

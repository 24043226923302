export default function getCurrencies(
    state = { error: null, success: null },
    action
  ) {
    switch (action.type) {
      case "FETCH_CURRENCIES_SUCCESS":
        return { ...state, ...{ success: action.success } };
      case "FETCH_CURRENCIES_ERROR":
        return { ...state, ...{ error: action.error } };
      default:
        return state;
    }
  }
  
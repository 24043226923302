import axios from "axios";
import { configConstants } from "../../constants";

function _success(success) {
  return { type: "FETCH_LOGIN_SUCCESS", success };
}
function _error(error) {
  return { type: "FETCH_LOGIN_ERROR", error };
}

export function resetLoginRequest() {
  return (dispatch) => {
    dispatch({
      type: "RESET_FETCH_LOGIN",
    });
  };
}

const LOGIN = "users/login";

export function getLoginRequest(data) {
  return (dispatch) => {
    let base_url = configConstants.AUTH_URL;

    axios({
      url: base_url + LOGIN,
      method: "post",
      dataType: "json",
      data: data,
    })
      .then((res) => {
        dispatch(_success(res));
      })
      .catch((error) => {
        dispatch(_error(error));
      });
  };
}

export default getLoginRequest;

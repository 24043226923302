export default function createProductProperty(
    state = { validation: null, success: null },
    action
  ) {
    switch (action.type) {
      case "CREATE_PRODUCT_PROPERTY_SUCCESS":
        return { ...state, ...{ success: action.success } };
      case "CREATE_PRODUCT_PROPERTY_VALIDATION_ERROR":
        return { ...state, ...{ validation: action.validation } };
      default:
        return state;
    }
  }
  
export default function importProduct(
    state = { validation: null, error: null },
    action
  ) {
    switch (action.type) {
      case "IMPORT_PRODUCT_SUCCESS":
        return { ...state, ...{ success: action.success } };
      case "IMPORT_PRODUCT_ERROR":
        return { ...state, ...{ error: action.error } };
      default:
        return state;
    }
  }
  
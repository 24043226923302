export default function errorHandling(
  state = {
    no_internet: null,
    server_error: null,
    unauthorized: null,
    bad_error: null,
  },
  action
) {
  switch (action.type) {
    case "NO_INTERNET":
      return {
        ...state,
        ...{
          no_internet: true,
          server_error: null,
          unauthorized: null,
          bad_error: null,
        },
      };
    case "SERVER_ERROR":
      return {
        ...state,
        ...{
          server_error: true,
          no_internet: null,
          unauthorized: null,
          bad_error: null,
        },
      };
    case "RESET_ERROR":
      return {
        ...state,
        ...{
          server_error: null,
          no_internet: null,
          unauthorized: null,
          bad_error: null,
        },
      };
    case "UNAUTHORIZED_ERROR": {
      return {
        ...state,
        ...{
          server_error: null,
          no_internet: null,
          unauthorized: action.message,
          bad_error: null,
        },
      };
    }
    case "BAD_ERROR": {
      return {
        ...state,
        ...{
          server_error: null,
          no_internet: null,
          unauthorized: null,
          bad_error: true,
        },
      };
    }
    default:
      return state;
  }
}

export default function updateRestaurantCategoryPriorities(
    state = { validation: null, success: null },
    action
  ) {
    switch (action.type) {
      case 'UPDATE_RESTAURANT_CATEGORIES_PRODUCTS_SUCCESS':
        return { ...state, ...{ success: action.success } };
      case 'UPDATE_RESTAURANT_CATEGORIES_PRODUCTS_VALIDATION_ERROR':
        return { ...state, ...{ validation: action.validation } };
      default:
        return state;
    }
  }
  